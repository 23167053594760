"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ExportPdf;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

require("jspdf-autotable");

function ExportPdf(columns) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "data";

  try {
    var JSpdf = typeof window !== "undefined" ? require("jspdf").jsPDF : null;
    var finalData = data; // Grab first item for data array, make sure it is also an array.
    // If it is an object, 'flatten' it into an array of strings.

    if (data.length && !Array.isArray(data[0])) {
      if ((0, _typeof2["default"])(data[0]) === "object") {
        // Turn data into an array of string arrays, without the `tableData` prop
        finalData = data.map(function (row) {
          return columns.map(function (col) {
            return col.exportTransformer ? col.exportTransformer(row) : row[col.field];
          });
        });
      }
    }

    if (JSpdf !== null) {
      var content = {
        startY: 50,
        head: [columns.map(function (col) {
          return col.title;
        })],
        body: finalData
      };
      var unit = "pt";
      var size = "A4";
      var orientation = "landscape";
      var doc = new JSpdf(orientation, unit, size);
      doc.setFontSize(15);
      doc.text(filename, 40, 40);
      doc.autoTable(content);
      doc.save(filename + ".pdf");
    }
  } catch (err) {
    console.error("exporting pdf : unable to import 'jspdf-autotable' : ".concat(err));
  }
}