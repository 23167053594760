import { useNavigate, useLocation } from "react-router";
const ReactRouter6Adapter = ({
  children
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adapter = {
    replace(location2) {
      navigate(location2.search || "?", {
        replace: true,
        state: location2.state
      });
    },
    push(location2) {
      navigate(location2.search || "?", {
        replace: false,
        state: location2.state
      });
    },
    get location() {
      return location;
    }
  };
  return children(adapter);
};
export {
  ReactRouter6Adapter
};
