"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ExportCsv;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _filefy = require("filefy");

function ExportCsv(columns) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "data";
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ",";

  try {
    var finalData = data; // Grab first item for data array, make sure it is also an array.
    // If it is an object, 'flatten' it into an array of strings.

    if (data.length && !Array.isArray(data[0])) {
      if ((0, _typeof2["default"])(data[0]) === "object") {
        // Turn data into an array of string arrays, without the `tableData` prop
        finalData = data.map(function (row) {
          return columns.map(function (col) {
            return col.exportTransformer ? col.exportTransformer(row) : row[col.field];
          });
        });
      }
    }

    var builder = new _filefy.CsvBuilder(filename + ".csv");
    builder.setDelimeter(delimiter).setColumns(columns.map(function (col) {
      return col.title;
    })).addRows(Array.from(finalData)).exportFile();
  } catch (err) {
    console.error("error in ExportCsv : ".concat(err));
  }
}